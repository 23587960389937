import React from "react"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"
import { useMediaQuery } from "react-responsive"

import { Grid, Col } from "../components/styles/Grid"
import Layout from "../components/Layout"
import Button from "../components/styles/Button"
import "../components/styles/klaus-gridski.css"
import { Section, ParagraphWrapper } from "../components/styles/Container"
import Dropzone from "../components/Dropzone"

const FormStyles = styled.div`
  label {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--mint);
    border-top: 1px solid var(--mint);
    margin-bottom: 30px;
  }
  input {
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    outline: none;
    padding-left: 4px;
  }
  textarea {
    background: none;
    outline: none;
    border: none;
    min-height: 150px;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
`

const HeadlineWrapper = styled.div`
  margin-top: 10rem;
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

const MobHeadlineWrapper = styled.div`
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

const Uploads = () => {
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 75em)",
  })
  const isTablet = useMediaQuery({
    query: "(min-device-width: 48em) and (max-device-width: 75em)",
  })
  const isMobile = useMediaQuery({
    query: "(max-device-width: 48em)",
  })
  return (
    <Layout>
      {isDesktop && (
        <div>
          <HeadlineWrapper>
            <h2>UPLOAD</h2>
            <h3>thanks for trusting me with your project</h3>
            <ParagraphWrapper>
              now you can drop your files, tell me your name an share your
              thoughts. It would be helpful if you leave me your telephone
              number so i can contact you if anything comes up. but if thats too
              sketchy for you, your email adress will do fine as well.
            </ParagraphWrapper>
          </HeadlineWrapper>
        </div>
      )}
      {(isMobile || isTablet) && (
        <MobHeadlineWrapper>
          <h2>UPLOAD</h2>
          <h3>thanks for trusting me with your project</h3>
          <ParagraphWrapper>
            now you can drop your files, tell me your name an share your
            thoughts. It would be helpful if you leave me your telephone number
            so i can contact you if anything comes up. but if thats too sketchy
            for you, your email adress will do fine as well.
          </ParagraphWrapper>
        </MobHeadlineWrapper>
      )}

      <Section>
        <Grid l="2" gap="7">
          <Col>
            <Dropzone></Dropzone>
          </Col>
          <Col>
            <FormStyles>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  message: "",
                }}
                onSubmit={(values, actions) => {
                  alert(JSON.stringify(values, null, 2))
                  actions.setSubmitting(false)
                }}
              >
                {() => (
                  <Form>
                    <div className="flex vertical">
                      <Field name="name" />
                      <label id="name" htmlFor="name">
                        name{" "}
                      </label>

                      <Field name="email" />
                      <label id="mail" htmlFor="email">
                        mail{" "}
                      </label>

                      <Field name="phone" />
                      <label id="phone" htmlFor="phone">
                        phone{" "}
                      </label>

                      <Field name="message" component="textarea" />
                      <label id="message" is="message" htmlFor="message">
                        here you can share your vision with me ↑
                      </label>
                      <Button type="submit">Send</Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </FormStyles>
          </Col>
        </Grid>
      </Section>
    </Layout>
  )
}

export default Uploads
