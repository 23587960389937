import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

const DropzoneStyle = styled.div`
  padding: 5rem;
  height: 75%;
  min-height: 200px;
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23AEC1B580' stroke-width='3' stroke-dasharray='20%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e"),
    rgba(174, 193, 181, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
`

function MyDropzone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <DropzoneStyle {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>and now drop it like it's hot!</p>
      ) : (
        <p>drag and drop your stuff here, or click to search files</p>
      )}
    </DropzoneStyle>
  )
}

export default MyDropzone
